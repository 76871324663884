<template>
    <!-- nav -->
	<nav class="nav">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack">
				<i class="icon icon-left">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					설정
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
    <!-- // nav -->
    <div id="container" class="container">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub mypage">
			<!-- content -->
			<section class="content">
				<div class="row">
					<ul class="settings">
						<li>
							<div class="setting__title">
								버전정보
							</div>
							<div class="setting__wrap">
								<strong class="skyblue">V1.0</strong>
							</div>
						</li>
						<li v-show="!pushError">
							<div class="setting__title">
								푸쉬알림
							</div>
							<div class="setting__wrap">
								<!-- [D] Onoff 스위치 -->
								<div class="onoff" @click="requestOpenNotificationSettings">
									<label>
										<input type="checkbox" class="chk blind" :checked="push === 'pushOn'" disabled> 
										<span class="onoff__switch"></span>
									</label>
								</div>
							</div>
						</li>
						<li @click="requestOpenAppSettings" v-show="!pushError">
							<div class="setting__title">
								접근 권한 설정
							</div>
							<div class="setting__wrap">
								<i class="icon icon-right"></i>
							</div>
						</li>
						<!-- <router-link to="/mypage/sns" custom v-slot="{ navigate }">
							<li v-show="isLogin" @click="navigate">
								<div class="setting__title">
									SNS 연결 설정
								</div>
								<div class="setting__wrap">
									<i class="icon icon-right"></i>
								</div>
							</li>
						</router-link> -->
						<li v-show="isLogin" @click="onClickLogout">
							<div class="setting__title">
								로그아웃
							</div>
						</li>
					</ul>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
</template>

<script>
import { useStore } from "vuex"
import { useRouter } from 'vue-router'
import { computed, onMounted, ref } from "vue"

export default {
  setup() {
    const router = useRouter();
		const store = useStore();

		const pushError = ref(false);

		const userData = computed(() => store.state.userData);
		const isLogin = computed(() => Object.keys(userData.value).length !== 0);
    const push = computed(() =>{
			return store.state.app.push;
		});

    const goBack = () => {
      router.back();
    }

    const requestOpenAppSettings = () => {
    	store.dispatch('app/requestOpenAppSettings');
    }
    const requestOpenNotificationSettings = () => {
			if(push.value === 'pushOn'){
      	store.dispatch('app/requestPushOff');
			}
			else {
      	store.dispatch('app/requestPushOn');
			}
    }

	const onClickLogout = () => {
    	store.commit("SET_USERDATA", {userData: {}});
		store.commit('mhm01/RESET_ALL');
		router.go('/')
	}

		onMounted(()=>{
      try {
      	store.dispatch('app/requestPushOnOff');
      } catch(e) {
				pushError.value = true;
      }
		});

    return {
			goBack,
			isLogin,
			onClickLogout,
			requestOpenAppSettings,
			requestOpenNotificationSettings,
			push,
			pushError,
			userData
    }
  },
}
</script>